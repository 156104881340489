<template>
 <v-row  dense>
  <v-col
        :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
    <v-text-field
      v-model="plz"
      solo
      type="number"
      placeholder="Ihre PLZ"
      label="Ihre PLZ"
      title="Ihre PLZ"
      /></v-col>
  <v-col
      :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
    <v-select
          label="Wo wollen Sie tanken?"
          title="Wo wollen Sie tanken?"
          solo
          :items= countrys
          v-model="land"
          item-text="text"
          item-value="value"
        >
        </v-select></v-col>
        <v-col
        :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
    <v-select
          label="Was wollen Sie tanken?"
          title="Was wollen Sie tanken?"
          solo
          :items= sprit
          v-model="asprit"
           item-text="text"
          item-value="value"
        >
        </v-select></v-col>
         <v-col
       :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
   <v-text-field
      v-model="verbrauch"
      solo
      placeholder="Ihr Verbrauch"
      type="number"
       label="Ihr Verbrauch"
       title="Geben Sie hier den durchschnittlichen Verbrauch Ihres Fahrzeugs an. Wenn Sie keinen Wert angeben wird mit einem Durchschnittswert von 7 Litern gerechnet."
      /></v-col>
       <v-col
       :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
   <v-text-field
      v-model="tank"
      solo
      placeholder="Ihre Tankgröße in Liter"
       label="Ihre Tankgröße in Liter"
      type="number"
      title="Ihre Tankgröße in Liter. Wenn Sie hier keinen Wert angeben, wird mit einem Durchschnittswert von 40 Litern gerechnet."
      /></v-col>
       <v-col
        :xl="2"
        :lg="2"
        :md="6"
        :sm="11"
        :cols="11"
      >
   <v-btn large elevation="4" title="Test starten!" @click="docheck()" color="secondary">Testen!</v-btn>
      </v-col>
      <v-dialog
      v-model="dialog"
     max-width="600"
     hidden-md-and-up
    >
      <v-card >
        <v-card-title class="text-h5 primary lighten-2">
         {{popuptitel}}
        </v-card-title>

        <v-card-text >
          {{popuptext}}
        <!--  <router-link :to="{ path: '/tipps', hash: '#' + link }" ><span @click="dialog = false">{{linktext}}</span></router-link>-->
        <a  @click="doclick()">{{linktext}}</a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

 <v-dialog
      v-model="dialog"
       class="hidden-md-and-down"
     max-width="600"
    >
      <v-card >
        <v-card-title class="text-h5 primary lighten-2">
         {{popuptitel}}
        </v-card-title>

        <v-card-text >
          {{popuptext}}
        <!--  <router-link :to="{ path: '/tipps', hash: '#' + link }" ><span @click="dialog = false">{{linktext}}</span></router-link>-->
        <a  href="/tipps.html">{{linktext}}</a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import Vue from 'vue'
import VueResource from 'vue-resource'
import router from '@/router/index.js'
Vue.use(VueResource)

export default {
  name: 'calcunit',

  data: () => ({
    plz: '',
    land: -1,
    verbrauch: null,
    tank: null,
    asprit: '',
    dialog: false,
    popuptitel: '',
    popuptext: '',
    linktext: '',
    link: '',
    countrys: [
      { text: 'Niederlande', value: 'nl' },
      { text: 'Belgien', value: 'be' },
      { text: 'Luxemburg', value: 'lu' },
      { text: 'Frankreich', value: 'fr' },
      { text: 'Österreich', value: 'at' },
      { text: 'Tschechien', value: 'cz' },
      { text: 'Polen', value: 'pl' },
      { text: 'Dänemark', value: 'dk' }
    ],
    sprit: [
      { text: 'Diesel', value: 'diesel' },
      { text: 'Benzin E5', value: 'e5' },
      { text: 'Benzin E10', value: 'e10' }
    ]
  }),
  methods: {
    docheck () {
      if (this.land && this.plz && this.asprit) {
        if (!this.verbrauch) {
          this.verbrauch = 7
        }
        if (!this.tank) {
          this.tank = 40
        }

        if (this.checkPLZ(this.plz)) {
          var baseURI = '/calculateresult?plz=' + this.plz + '&land=' + this.land + '&treibstoff=' + this.asprit + '&verbrauch=' + this.verbrauch + '&tank=' + this.tank
          Vue.http.options.emulateJSON = true
          Vue.http.get(baseURI)
            .then((result) => {
              if (result.data) {
                this.showPopUp(result.data)
              } else {
                this.showErrorPopUp()
              }
            })
        } else {
          this.showPLZPopUp()
        }
      } else {
        this.showExceptionPopUp()
      }
    },
    doclick () {
      this.dialog = false
      router.push({ name: 'Tipps', params: { goto: this.land } })
    },

    showPopUp (summe) {
      if (summe > 0) {
        var summetext = (String(summe).replace('.', ','))
        this.popuptitel = 'Es lohnt sich für Sie ' + this.getCountryText(this.land) + ' zu tanken!'
        this.popuptext = 'Wenn Sie in ' + this.decriptCountry(this.land) + ' tanken, machen Sie ca. ' + summetext + ' € Gewinn. Vielleicht können Sie zusätzlich den Tankaufenthalt mit etwas Anderem verbinden. Schauen Sie einfach in unserer Liste nach, was sich für lohnt in '
        this.linktext = this.decriptCountry(this.land)
        this.link = this.land
      } else {
        var summetextneg = (String(Number(summe) * -1).replace('.', ','))
        this.popuptitel = 'Es lohnt sich für Sie nicht ' + this.getCountryText(this.land) + '  zu tanken.'
        this.popuptext = 'Wenn Sie in ' + this.decriptCountry(this.land) + ' tanken, lohnt es sich für Sie nicht. Sie machen ca. ' + summetextneg + ' € Verlust. Aber vielleicht können Sie den Tankaufenthalt mit etwas Anderem verbinden. Schauen Sie einfach in unserer Liste nach, was sich für lohnt in '
        this.linktext = this.decriptCountry(this.land)
        this.link = this.land
      }
      this.dialog = true
    },
    showExceptionPopUp () {
      this.popuptitel = 'Es fehlen Informationen'
      this.popuptext = 'Um zu berechnen ob es sich für Sie lohnt im Ausland zu tanken, müssen Sie mindestens Ihre Postleitzahl, wo Sie tanken wollen und was Sie tanken wollen angeben.'
      this.dialog = true
    },
    showPLZPopUp () {
      this.popuptitel = 'Falsche Postleitzahl'
      this.popuptext = 'Bei der von Ihnen angegeben Zahl handelt es sich nicht um eine deutsche Postleitzahl.'
      this.dialog = true
    },
    showErrorPopUp () {
      this.popuptitel = 'Serverüberlastung'
      this.popuptext = 'Es tut uns leid aktuell ist unser Server überlastet. Bitte versuchen Sie es im ein paar Minuten erneut.'
      this.dialog = true
    },
    checkPLZ (plz) {
      return !!(plz.match(/^[0-9]{5}$/))
    },
    decriptCountry (counin) {
      switch (String(counin)) {
        case 'nl':
          return 'Niederlande'
        case 'be':
          return 'Belgien'
        case 'lu':
          return 'Luxemburg'
        case 'fr':
          return 'Frankreich'
        case 'ch':
          return 'Schweiz'
        case 'at':
          return 'Österreich'
        case 'cz':
          return 'Tschechien'
        case 'pl':
          return 'Polen'
        case 'dk':
          return 'Dänemark'
      }
    },
    getCountryText (counin) {
      switch (String(counin)) {
        case 'nl':
          return 'in den Niederlanden'
        case 'be':
          return 'in Belgien'
        case 'lu':
          return 'in Luxemburg'
        case 'fr':
          return 'in Frankreich'
        case 'ch':
          return 'in der Schweiz'
        case 'at':
          return 'in Österreich'
        case 'cz':
          return 'in Tschechien'
        case 'pl':
          return 'in Polen'
        case 'dk':
          return 'in Dänemark'
      }
    }
  }
}
</script>
